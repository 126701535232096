export const genderTypes = [
  {
    value: 'male',
    label: 'genderTypes.male'
  },
  {
    value: 'female',
    label: 'genderTypes.female'
  },
  {
    value: 'other',
    label: 'genderTypes.whoCares'
  }
]
