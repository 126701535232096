<template>
  <!-- Dialog Cancel Account -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    width="550px"
    top="8vh"
    empty-header
    @close="closeDialog"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/fogg-fatal-error-2.svg')"
      :title="$t('areYouSure')"
      :subtitle="message"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('yes')"
        :cancel-btn-text="$t('noTakeMeBack')"
        variant-button="red"
        outline-button
        space-between-buttons
        @cancel="closeDialog"
        @submit="cancelAccount"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Cancel Account -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialogCancelAccount',
  props: {
    visible: Boolean
  },

  computed: {
    ...mapGetters('user', ['isFreeTrial']),

    message () {
      if (this.isFreeTrial) {
        return 'Your account will be deleted and all your ideas will be lost.'
      }

      return 'After the billing cycle ends, your account will be deleted and all of your ideas will be lost.'
    }
  },

  methods: {
    ...mapActions('user', ['updateUser']),

    closeDialog () {
      this.$emit('close')
    },

    cancelAccount () {
      const user = Object.assign({}, this.$store.state.user)
      user.status = 2
      this.updateUser(user)
        .then(_ => {
          this.closeDialog()
          this.$intercom.trackEvent('Cancelled account')
        })
    }
  }
}
</script>
