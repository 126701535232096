<template>
  <v-app>
    <!-- My Profile -->
    <div id="my-profile">

      <!-- Container -->
      <div class="container-1200">

        <!-- Content -->
        <div class="content ml-auto mr-auto">
          <!-- Header -->
          <header class="d-flex justify-content-between align-center">
            <!-- Title -->
            <h1>{{ $t('pages.myProfile.title') }}</h1>
            <!-- /Title -->
            <locale-changer />
          </header>
          <!-- /Header -->

          <!-- Profile Card -->
          <div class="profile-card">
            <el-row :gutter="40" class="d-flex flex-wrap">

              <!-- Left Side -->
              <el-col :md="14">

                <!-- User Details -->
                <div class="user-details">

                  <!-- Avatar -->
                  <ib-tooltip
                    placement="top"
                    popper-class="plan-popper"
                  >
                    <!-- Tooltip Content -->
                    <div slot="content" class="tooltip-content">
                      <p>{{ $t('pages.onBoarding.dialogUserDetails.addYourPicture') }}</p>
                    </div>
                    <!-- /Tooltip Content -->

                    <el-upload
                      class="avatar-uploader"
                      action="/api/v1/upload"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :on-success="handleAvatarSuccess"
                      :class="{ 'man': userGender === 'male', 'woman': userGender === 'female' }"
                    >
                      <div v-if="image">
                        <img :src="image" class="avatar">
                        <i class="el-icon-close" @click.stop="removeImageAvatar" />
                      </div>
                      <i v-else class="el-icon-plus avatar-uploader-icon" />

                      <div v-if="user.avatarPreview">
                        <img :src="user.avatarPreview" class="avatar">
                        <i class="el-icon-close" @click.stop="removeImageAvatar" />
                      </div>

                      <!-- Avatar Default -->
                      <div
                        v-if="!userImage && !user.avatarPreview"
                        class="avatar"
                        :class="{ 'man': userGender === 'male', 'woman': userGender === 'female' }"
                      >
                        <i class="el-icon-plus avatar-uploader-icon" />
                      </div>
                    <!-- /Avatar Default -->
                    </el-upload>
                  </ib-tooltip>
                  <!-- /Avatar -->

                  <!-- First Name & Last Name -->
                  <el-row :gutter="10" class="line-input-group">

                    <!-- First Name Input -->
                    <el-col :sm="{span: 9, offset: 3}">
                      <el-input v-model="user.firstName" class="line-input text-center" :placeholder="`${$t('fields.firstName')}`" />
                    </el-col>
                    <!-- /First Name Input -->

                    <!-- Last Name Input -->
                    <el-col :sm="9">
                      <el-input v-model="user.lastName" class="line-input text-center" :placeholder="`${$t('fields.lastName')}`" />
                    </el-col>
                  <!-- /Last Name Input -->

                  </el-row>
                  <!-- /First Name & Last Name -->

                  <!-- Plan -->
                  <p class="text-label mb-md-5">
                    {{ subscriptionPlanName }}
                  </p>
                  <!-- /Plan -->

                  <!-- Form -->
                  <el-form :model="user">

                    <!-- Gender & Age -->
                    <el-row :gutter="10">

                      <!-- Gender -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.gender')}`"
                          :class="{'focused': user.gender}"
                        >
                          <ib-select v-model="user.gender">
                            <ib-option
                              v-for="gender in options.gender"
                              :key="gender.value"
                              :label="$t(gender.label)"
                              :value="gender.value"
                            />
                          </ib-select>
                        </el-form-item>
                      </el-col>
                      <!-- /Gender -->

                      <!-- Age -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.age')}`"
                          :class="{'focused': user.age}"
                        >
                          <ib-select v-model="user.age">
                            <ib-option
                              v-for="age in options.age"
                              :key="age.value"
                              :label="age.label"
                              :value="age.value"
                            />
                          </ib-select>
                        </el-form-item>
                      </el-col>
                    <!-- /Age -->

                    </el-row>
                    <!-- /Gender & Age -->

                    <!-- Country & City -->
                    <el-row :gutter="10">

                      <!-- Country -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.country')}`"
                          :class="{'focused': user.country}"
                        >
                          <ib-select v-model="user.country">
                            <ib-option
                              v-for="country in countries"
                              :key="country.code"
                              :label="country.name"
                              :value="country.code"
                            />
                          </ib-select>
                        </el-form-item>
                      </el-col>
                      <!-- /Country -->

                      <!-- City -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('fields.city')}`"
                          :class="{'focused': focus.city}"
                        >
                          <el-input
                            v-model="user.city"
                            placeholder=" "
                            maxlength="90"
                            @focus="toggleFocus('city')"
                            @blur="toggleFocus('city')"
                          />
                        </el-form-item>
                      </el-col>
                    <!-- /City -->

                    </el-row>
                  <!-- /Country & City -->

                  </el-form>
                <!-- /Form -->

                </div>
              <!-- /User Details -->

              </el-col>
              <!-- /Left Side -->

              <!-- Right Side -->
              <el-col :md="10" class="d-flex flex-column justify-content-between">

                <!-- User Email and Password -->
                <div class="user-credentials">

                  <!-- Form -->
                  <el-form ref="user" :rules="rules" :model="user">

                    <!-- Old Email Address -->
                    <el-form-item
                      class="input-inside-label focused first" :label="`${$t('fields.emailAddress')}`"
                      :class="{'focused': focus.email}"
                    >
                      <el-input
                        v-model="user.email"
                        placeholder=" "
                        :disabled="true"
                        class="mb-2"
                        @focus="toggleFocus('email')"
                        @blur="toggleFocus('email')"
                      />
                      <p
                        v-if="hideChanges"
                        class="change-email-indicator"
                        @click="hideChanges = false"
                      >
                        {{ $t('pages.myProfile.changeEmailAddress') }}
                      </p>

                    </el-form-item>
                    <!-- /Old Email Address -->

                    <!-- Alert Error -->
                    <transition name="fade">
                      <div v-if="showErrorAlertLogin" style="margin-bottom: 20px;">
                        <el-alert
                          :title="errorMessageLogin"
                          type="error"
                          show-icon
                          :closable="false"
                        />
                      </div>
                    </transition>
                    <!-- /Alert Error -->

                    <!-- Change Email Address -->
                    <div class="change-wrapper">
                      <div v-if="!hideChanges" class="inner-wrapper">

                        <!-- New Email Address -->
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.newEmail')}`"
                          :class="{'focused': focus.newEmail}"
                          prop="newEmail"
                        >
                          <el-input
                            v-model="user.newEmail"
                            type="email"
                            placeholder=" "
                            clearable
                            @input="onInput()"
                            @focus="toggleFocus('newEmail')"
                            @blur="toggleFocus('newEmail')"
                          />
                        </el-form-item>
                        <!-- /New Email Address -->

                        <!-- Confirm New Email Address -->
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.confirmNewEmail')}`"
                          :class="{'focused': focus.confirmEmail}"
                          prop="confirmEmail"
                        >
                          <el-input
                            v-model="user.confirmEmail"
                            type="email"
                            placeholder=" "
                            clearable
                            @input="onInput()"
                            @focus="toggleFocus('confirmEmail')"
                            @blur="toggleFocus('confirmEmail')"
                          />
                        </el-form-item>
                      <!-- Confirm New Email Address -->

                      </div>
                    </div>
                    <!-- /Change Email Address -->

                    <!-- /Cancel New Email Address -->
                    <a
                      class="cancel"
                      :class="{'visible': !hideChanges}"
                      @click="toggleChanges"
                    >
                      {{ $t('cancel') }}
                    </a>
                    <!-- /Cancel New Email Address -->

                    <!-- Change Password-->
                    <div class="change-wrapper mb-3">
                      <div v-if="!hideChangesPass" class="inner-wrapper">

                        <!-- New Password-->
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.newPassword')}`"
                          :class="{'focused': focus.newPassword}"
                          prop="newPassword"
                        >
                          <el-input
                            v-model="user.newPassword"
                            placeholder=" "
                            clearable
                            show-password
                            @input="onInput()"
                            @focus="toggleFocusPass('newPassword')"
                            @blur="toggleFocusPass('newPassword')"
                          />
                        </el-form-item>
                        <!-- /New Password-->

                        <!-- Confirm New Password-->
                        <el-form-item
                          class="input-inside-label" :label="`${$t('fields.confirmNewPassword')}`"
                          :class="{'focused': focus.confirmPassword}"
                          prop="confirmPassword"
                        >
                          <el-input
                            v-model="user.confirmPassword"
                            placeholder=" "
                            clearable
                            show-password
                            @input="onInput()"
                            @focus="toggleFocusPass('confirmPassword')"
                            @blur="toggleFocusPass('confirmPassword')"
                          />
                        </el-form-item>
                      <!-- Confirm New Password-->

                      </div>
                      <p
                        v-if="hideChangesPass"
                        class="change-password-indicator"
                        @click="hideChangesPass = false"
                      >
                        {{ $t('pages.myProfile.changePassword') }}
                      </p>
                    </div>
                    <!-- /Change Password-->

                    <!-- Cancel New Password-->
                    <a class="cancel"
                       :class="{'visible': !hideChangesPass}"
                       @click="toggleChangesPass"
                    >
                      {{ $t('cancel') }}
                    </a>
                  <!-- /Cancel New Password-->
                  </el-form>
                <!-- /Form -->

                </div>
                <!-- /User Email and Password -->

                <div v-if="isDisabled" class="cancel-wrapper">
                  <!-- Cancel Account-->
                  <p class="account-canceled">
                    {{ $t('pages.myProfile.accountCanceledMessage') }}
                  </p>
                <!-- /Cancel Account-->
                </div>

                <div
                  v-else
                  class="d-flex justify-content-center pb-md-4"
                >
                  <!-- Cancel Account-->
                  <ib-button
                    class="text-uppercase"
                    size="sm"
                    variant="red"
                    submit-button
                    @click="dialogCancelAccountVisible = !dialogCancelAccountVisible"
                  >
                    {{ $t('cancelSubscription') }}
                  </ib-button>
                <!-- /Cancel Account-->
                </div>

              </el-col>
            <!-- /Right Side -->

            </el-row>
          </div>
          <!-- /Profile Card -->

          <!-- Back and Save Buttons-->
          <el-row :gutter="10" class="d-flex justify-content-sm-end mt-4">
            <el-col :xs="12" :sm="6">
              <ib-button
                variant="white"
                class="w-100"
                size="lg"
                font-family="regular"
                @click="$router.go(-1)"
              >
                {{ $t('back') }}
              </ib-button>
            </el-col>

            <el-col :xs="12" :sm="6">
              <ib-button
                class="font-weight-bold text-uppercase w-100"
                :disabled="!isAddEnabled"
                :loading="loadingContinue"
                size="lg"
                font-family="regular"
                @click="onSave"
              >
                {{ $t('save') }}
              </ib-button>
            </el-col>
          </el-row>
        <!-- /Back and Save Buttons-->

        </div>
      <!-- /Content -->

      </div>
      <!-- /Container -->

      <!-- Dialog Cancel Account -->
      <dialog-cancel-account
        :visible="dialogCancelAccountVisible"
        @close="dialogCancelAccountVisible = false"
      />
    <!-- /Dialog Cancel Account -->

    </div>
  <!-- /My Profile -->
  </v-app>
</template>

<script>
import DialogCancelAccount from '@/views/Home/AccountSettings/MyProfile/Dialogs/DialogCancelAccount'
import IbOption from '@/components/_v2/Select/IbOption'
import LocaleChanger from '@/components/LocaleChanger.vue'
import MixinCountries from '@/mixins/countries'

import { mapActions, mapGetters } from 'vuex'
import { genderTypes } from '@/data/genderTypes'
import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  name: 'MyProfile',

  components: {
    LocaleChanger,
    IbOption,
    DialogCancelAccount
  },

  mixins: [
    MixinCountries
  ],

  data () {
    const validateConfirmEmail = (rule, value, callback) => {
      if (value !== this.user.newEmail) {
        callback(new Error(this.$t('validations.twoInputsDoNotMatch')))
      } else {
        callback()
      }
    }

    const validateNewEmail = (rule, value, callback) => {
      if (value === '' && this.user.confirmEmail !== '') {
        callback(new Error(this.$t('validations.pleaseInputAnEmail')))
      } else {
        callback()
      }
    }

    const validateNewEmailExist = (rule, value, callback) => {
      if (value === this.user.email) {
        callback(new Error(this.$t('validations.accountWithThisEmailAlreadyExists')))
      } else {
        callback()
      }
    }

    const validateConfirmEmailExist = (rule, value, callback) => {
      if (value === this.user.email) {
        callback(new Error(this.$t('validations.accountWithThisEmailAlreadyExists')))
      } else {
        callback()
      }
    }

    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.user.newPassword) {
        callback(new Error('Two inputs don\'t match!'))
      } else if (value !== '' && value.length < 6) {
        callback(new Error(this.$t('validations.passwordMustBe')))
      } else {
        callback()
      }
    }

    const validateNewPassword = (rule, value, callback) => {
      if (value === '' && this.user.confirmPassword !== '') {
        callback(new Error(this.$t('validations.pleaseInputAPassword')))
      } else {
        callback()
      }
    }

    return {
      dialogCancelAccountVisible: false,
      dialogCancelSubscriptionVisible: false,
      image: null,
      user: {
        avatar: '',
        avatarPreview: '',
        firstName: '',
        lastName: '',
        gender: '',
        age: '',
        country: '',
        city: '',
        email: '',
        newEmail: '',
        confirmEmail: '',
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      focus: {
        city: false,
        email: false,
        newEmail: false,
        confirmEmail: false,
        password: false,
        newPassword: false,
        confirmPassword: false
      },
      errorMessageLogin: '',
      showErrorAlertLogin: false,
      loadingContinue: false,
      options: {
        gender: genderTypes,
        age: [
          {
            value: '18-25',
            label: '18-25'
          },
          {
            value: '26-35',
            label: '26-35'
          },
          {
            value: '36-50',
            label: '36-50'
          },
          {
            value: '51+',
            label: '51+'
          }
        ]
      },
      hideChanges: true,
      hideChangesPass: true,
      rules: {
        newEmail: [
          { type: 'email', message: this.$t('validations.enterValidEmail'), trigger: 'submit' },
          { validator: validateNewEmail, trigger: 'submit' },
          { validator: validateNewEmailExist, trigger: 'submit' }
        ],
        confirmEmail: [
          { validator: validateConfirmEmail, trigger: 'submit' },
          { validator: validateConfirmEmailExist, trigger: 'submit' }
        ],
        newPassword: [
          { message: this.$t('validations.enterYourPassword'), trigger: 'submit' },
          { validator: validateNewPassword, trigger: 'submit' }
        ],
        confirmPassword: [
          { validator: validateConfirmPassword, trigger: 'submit' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('user', [
      'images',
      'isDisabled',
      'userGender',
      'userImage',
      'subscriptionPlanName'
    ]),

    isAddEnabled () {
      return this.user.firstName && this.user.lastName
    }

    // linkClass () {
    //   return !this.hideChanges && !this.hideChangesPass ? 'link-expanded' : 'link-collapsed'
    // }
  },

  mounted () {
    this.setUserPredefinedValues()
    this.getMyProfile()
      .then(response => {
        this.setUserImage(response.user.image)
        this.setUserPredefinedValues()
        this.focus.gender = true
        this.focus.age = true
        if (this.$store.state.user.city) {
          this.focus.city = true
        }
        this.focus.country = true
      })
  },

  methods: {
    ...mapActions('user', [
      'getMyProfile',
      'updateUser'
    ]),

    setUserPredefinedValues () {
      const { firstName, lastName, age, gender, country, city, email, images } = this.$store.state.user
      this.user.firstName = firstName
      this.user.lastName = lastName
      this.user.age = age
      this.user.gender = gender
      this.user.country = country
      this.user.city = city
      this.user.email = email
      this.image = getImageBySize(images, 'medium')
    },

    setUserImage (image) {
      if (!image) {
        this.image = null

        return
      }
      this.user.avatar = image.filename
      this.user.avatarPreview = `${image.path}${image.filename}`
    },

    toggleChanges () {
      this.resetUserEmail()
      this.hideChanges = true
    },
    toggleChangesPass () {
      this.resetUserPassword()
      this.hideChangesPass = true
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.user[input]) {
        this.focus[input] = true
      }
    },

    toggleFocusPass (input) {
      this.focus[input] = !this.focus[input]

      if (this.user[input]) {
        this.focus[input] = true
      }
    },

    beforeAvatarUpload (file) {
      const isAcceptedFormat = file.type === 'image/jpeg' || file.type === 'image/png'
      const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

      if (!isAcceptedFormat) {
        // this.$message.error('Picture must be JPG or PNG format!')
        alert(this.$t('validations.pictureMustBeJpegOrPngFormat'))
      }
      if (!isImageSizeLowerThen5M) {
        // this.$message.error('Picture size can not exceed 5MB!')
        alert(this.$t('validations.pictureSizeCanNotExceed', { size: '5MB' }))
      }

      return isAcceptedFormat && isImageSizeLowerThen5M
    },

    handleAvatarSuccess (result, file) {
      this.user.avatar = result.payload.filename
      this.user.avatarPreview = URL.createObjectURL(file.raw)
    },

    removeImageAvatar () {
      this.user.avatar = ''
      this.user.avatarPreview = ''
      this.image = ''
    },

    resetUserEmail () {
      this.user.newEmail = ''
      this.user.confirmEmail = ''
      this.focus.newEmail = false
      this.focus.confirmEmail = false
    },

    resetUserPassword () {
      this.user.newPassword = ''
      this.user.confirmPassword = ''
      this.focus.newPassword = false
      this.focus.confirmPassword = false
    },

    onSave () {
      this.loadingContinue = true
      this.$refs.user.validate((valid) => {
        if (valid) {
          this.updateUser(this.user)
            .then((response) => {
              const user = response.user
              this.$intercom.update({
                name: `${user.firstName} ${user.lastName}`,
                email: user.email
              })
              this.$intercom.trackEvent('Updated profile')

              if (this.$store.getters['idea/isRoleOwner']) {
                this.$store.commit('idea/setIdeaOwnerEmail', user.email)
                this.$store.commit('idea/setIdeaOwnerImage', user.image)
              }

              history.go(-1)
              this.loadingContinue = false
            }).catch(e => {
              this.loadingContinue = false

              if (typeof e.response !== 'undefined' && e.response.status === 404) {
                this.errorMessageLogin = this.$t('validations.incorrectEmailOrPassword')
                this.showErrorAlertLogin = true

                return
              }

              if (typeof e.response !== 'undefined' && e.response.status === 409) {
                this.errorMessageLogin = this.$t('validations.accountWithThisEmailAlreadyExistsOrHasBeenCanceled')
                this.showErrorAlertLogin = true

                return
              }

              this.errorMessageLogin = this.$t('validations.sorrySomethingWentWrong')
              this.showErrorAlertLogin = true
            })
        } else {
          this.loadingContinue = false

          return false
        }
      })
    },

    onInput () {
      this.showErrorAlertLogin = false
      this.$refs.user.clearValidate()
    }
  }
}
</script>
